import { ChevronRight, SupportAgentOutlined } from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  Grow,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography, Badge, Avatar,
  Fab
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
// import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getFullName } from "../../shared/function";
import config from "../../config";
import AskQuestionModal from "../askQuestionModal";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    zIndex: 2
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  menuItem: {
    width: 200,
    display: "flex",
    justifyContent: "space-between"
  }
}));

const UserMenu = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const subAnchorRef = React.useRef(null);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [openAskQuestionModal, setOpenAskQuestionModal] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setSubMenuOpen(false);
  };

  const handleSubmenuClick = () => {
    setSubMenuOpen(!subMenuOpen); // toggle the submenu open state when clicked
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/logout");
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const handleOpenAskQuestionModal = () => {
    setOpenAskQuestionModal(true);
    setOpen(false);
    setSubMenuOpen(false);
  };

  const handleOpenOnlineDoc = () => {
    window.open(
      "https://www.newline.co/fullstack-react/assets/media/sGEMe/MNzue/30-days-of-react-ebook-fullstackio.pdf",
      "_blank"
    );
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <div className={classes.root}>
  <IconButton
    ref={anchorRef}
    onClick={handleToggle}
    sx={{ p: 0 }}
    aria-controls={open ? "menu-list-grow" : undefined}
    aria-haspopup="true"
  >
    
      <Fab size="small" color="primary" sx={{ borderRadius: "50%" }}>
      <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
    >
        <SupportAgentOutlined />
        </StyledBadge>
      </Fab>
    
  </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right bottom"
            }}
          >
            <Paper sx={{ mt: 0.5 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <Typography color="primary" sx={{ pb: "6px", px: "16px" }}>
                    {getFullName(currentUser)}
                  </Typography>
                  <Divider />
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleChangePassword}
                  >
                    Modifier le mot de passe
                  </MenuItem>
                  <MenuItem
                    ref={subAnchorRef}
                    className={classes.menuItem}
                    onClick={handleSubmenuClick}
                  >
                   {"Besoin d'aide"}
                    <ChevronRight />
                  </MenuItem>
                  <Menu
                    anchorEl={subAnchorRef.current}
                    open={subMenuOpen}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    sx={{
                      transform: "translateX(-195px)"
                    }}
                  >
                    <MenuItem disabled onClick={handleOpenOnlineDoc}>
                      Documentation en ligne
                    </MenuItem>
                    <MenuItem onClick={handleOpenAskQuestionModal}>
                      Posez une Question
                    </MenuItem>
                  </Menu>
                  <MenuItem className={classes.menuItem} onClick={handleLogout}>
                    Déconnexion
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AskQuestionModal
        open={openAskQuestionModal}
        onClose={() => setOpenAskQuestionModal(false)}
      />
    </div>
  );
};

export default UserMenu;
